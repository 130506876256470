import React, { Component } from "react";

class CompareTool extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.async = false;
    script.src = "https://code.komparu.com/u16cP";
    const compareTool = this.div.appendChild(script);
  }

  render() {
    return (
      <div className="App">
        <h1>Beste autoverzekering vergelijken</h1>
        <p>
          Direct de beste autoverzekering vergelijken? Vergelijk de goedkoopste
          verzekeraars en <br /> binnen 10 minuten kun je al een nieuwe
          autoverzekering afsluiten
        </p>
        <div className="compare-tool" id="kz" ref={el => (this.div = el)}></div>
      </div>
    );
  }
}

export default CompareTool;
